import React from 'react';
import { Segment } from 'semantic-ui-react';
import { SkillValue } from '../interfaces';

const Instructions = () => {
  return (
    <Segment id="instructions" className="instructions">
      <h2>Instructions</h2>
      <p>Here you can rate your skills in the following scale:</p>
      <div className="instruction-line">
        <span
          className={`category-level instruction-icon level-${SkillValue.NoUnderstanding}`}
        >
          {SkillValue.NoUnderstanding}
        </span>{' '}
        No understanding
      </div>
      <div className="instruction-line">
        <span
          className={`category-level instruction-icon level-${SkillValue.Beginner}`}
        >
          {SkillValue.Beginner}
        </span>{' '}
        Beginner (can discuss about topic during lunch)
      </div>
      <div className="instruction-line">
        <span
          className={`category-level instruction-icon level-${SkillValue.Amateur}`}
        >
          {SkillValue.Amateur}
        </span>{' '}
        Amateur (can do something, but not production quality work)
      </div>
      <div className="instruction-line">
        <span
          className={`category-level instruction-icon level-${SkillValue.Professional}`}
        >
          {SkillValue.Professional}
        </span>{' '}
        Professional (can do production quality work)
      </div>
      <div className="instruction-line">
        <span
          className={`category-level instruction-icon level-${SkillValue.Specialist}`}
        >
          {SkillValue.Specialist}
        </span>{' '}
        Specialist (knows the details, can assist others, and do
        architecture)
      </div>
      <div className="instruction-line">
        <span
          className={`category-level instruction-icon level-${SkillValue.Guru}`}
        >
          {SkillValue.Guru}
        </span>{' '}
        Guru, in top 10% (best in company and in customer)
      </div>
      {/*       <Segment>
        <p className="personal-preference">
          <span>&#x2B50;</span> Mark if you have personal preference
          for the skill
        </p>
      </Segment> */}
    </Segment>
  );
};

export default Instructions;
