import { Map } from 'immutable';
import { SkillValueData } from '../interfaces';

/**
 * computeTotalPoints total points computation for a sequence of type Map<string, Map<string, SkillValueData>>
 * @param mapSequence
 * @returns
 */
const computeTotalPoints = (
  mapSequence: Map<string, Map<string, SkillValueData>>
) => {
  if (!mapSequence) {
    console.error('computeTotalPoints: mapSequence is empty!');
    return;
  }
  return mapSequence.reduce((totalPoints, entry) => {
    let points = 0;
    entry.forEach((value, key) => {
      let skillValue = null;
      if (value.skillValue) {
        skillValue = value.skillValue;
      } else if (key === 'skillValue') {
        skillValue = value;
      }
      points = skillValue && Number(skillValue);
      if (points >= 0) {
        totalPoints = totalPoints + points;
      }
    });
    return totalPoints;
  }, 0);
};

export { computeTotalPoints };
