import React, { useEffect, useState } from 'react';
import { UserTuple } from './interfaces';
import { Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import RedirectingSpinner from './components/redirecting-spinner';
import { setCookie, getCookieValue } from './helpers/storage';
import * as server from './api/skills-backend';

const loginUser = async (loginUserInfo: UserTuple, token: any) => {
  console.debug('Login user ', loginUserInfo[1].toString());
  const response = await server.default.instance.post(
    'login',
    JSON.stringify({
      email: loginUserInfo[1],
      googleIdToken: token.credential,
    })
  );
  return response;
};

const LoginView = () => {
  const [redirect, setRedirect] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const location = useLocation();

  const dispatch = useDispatch();
  const redirectAnimation = useSelector(({ login }) => {
    return login.redirectAnimation;
  });
  const errorStringified = useSelector(({ login }) => {
    return login.error;
  });

  useEffect(() => {
    console.log('Login view auth', authToken);
    if (authToken !== null) {
      // Set the AUTH token for any request
      server.default.instance.interceptors.request.use((config) => {
        // const token = localStorage.getItem('skills-auth-token');
        const token = getCookieValue('skills-auth-token');
        if (token) {
          config.headers.Authorization = token
            ? `Bearer ${token}`
            : '';
        }
        return config;
      });
    }
  }, [authToken]);

  useEffect(() => {
    (window as any).google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentials,
      context: 'signin',
      ux_mode: 'popup',
      use_fedcm_for_prompt: true,
    });
    (window as any).google.accounts.id.prompt();
    (window as any).google.accounts.id.renderButton(
      document.getElementById('signinDiv'),
      {
        theme: 'outline',
        size: 'large',
        click_listener: onClickHandler,
      }
    );
    // };
  }, []);

  const onClickHandler = (response) => {
    console.log('Google button response', response);
  };

  const validateGoogleToken = (tokenPayload: any) => {
    const { iss, aud, exp } = tokenPayload;
    const googleIssuer = 'https://accounts.google.com';
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpiration = exp;
    if (
      iss !== googleIssuer ||
      aud !== googleClientId ||
      currentTime >= tokenExpiration
    ) {
      console.error('Google token validation failed');
      return false;
    }
    return true;
  };

  const onSuccess = (token) => {
    const arrayToken = token.credential.split('.');
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    let loginInformation: UserTuple = ['', ''];
    if (validateGoogleToken(tokenPayload)) {
      loginInformation = [
        ''.concat(tokenPayload.name),
        ''.concat(tokenPayload.email),
      ] as UserTuple;
      dispatch({
        type: 'login/gLoginInformation',
        payload: loginInformation,
      });
      dispatch({
        type: 'login/setRedirectAnimation',
        payload: 'active',
      });
    }

    // Immediately login to Java server after receiving G token
    loginUser(loginInformation, token)
      .then((response) => {
        console.debug(
          'User logged in to Java server! Here is the server response: ',
          response
        );
        const {
          authorizationToken,
          zendeskData: { profile },
        } = response.data;
        // setLocalStorage(authorizationToken);
        setCookie('skills-auth-token', authorizationToken);
        setAuthToken(authorizationToken); // use token from localstorage or redux store later, now only this one available
        console.debug(
          'Auth token was set in React component!',
          authorizationToken
        );
        if (profile.role) {
          setCookie('skills-my-role', profile.role);
        }
        dispatch({
          type: 'login/addProfileData',
          payload: response.data,
        });
        setRedirect(true);

        // Initialise logout button and its state now that user has been signed in
        dispatch({ type: 'logout/initValues' });
      })
      .catch((error) => {
        console.error(`Error message: `, error.message);
        // Finally stop logging loading animation if token is missing
        dispatch({ type: 'login/setRedirectAnimation', payload: '' });
        dispatch({
          type: 'login/setErrorObj',
          payload: error,
        });
      });

    console.debug(`process.env.NODE_ENV=${process.env.NODE_ENV}`);
  };

  const onFailure = ({ error }) => {
    console.error('Here on failure ', error.message);
  };

  if (redirect) return <Navigate to={location.state.from} />;
  let error = null;
  if (errorStringified) {
    error = JSON.parse(errorStringified);
  }

  const handleCredentials = (response) => {
    console.log('Response from Google loaded', response);
    if (response.credential) {
      onSuccess(response);
    } else {
      onFailure(response);
    }
  };

  return (
    <div className="login-view">
      <div className="login-container">
        <h1>Skills Login</h1>
        <Segment>
          <h2>Please sign in</h2>
          <p>You can login with any account using Google login.</p>
          <p>
            Additional functionality with Pepron domain consultant
            accounts.
          </p>
          <div id="signinDiv"></div>
          {redirectAnimation === 'active' ? (
            <RedirectingSpinner />
          ) : null}
        </Segment>
        {error ? (
          <div className="ui error message errorStatus">
            <i
              className="close icon"
              onClick={(e) => {
                dispatch({
                  type: 'login/setErrorObj',
                  payload: '',
                });
                error = null;
              }}
            ></i>
            <div className="error-header">Internal Server Error</div>
            <ul className="list">
              <li>{error?.message}</li>
              {error?.config?.baseURL && error?.config?.url ? (
                <li>{`Request: ${error.config.baseURL}${error.config.url}`}</li>
              ) : null}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoginView;
