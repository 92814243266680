import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import { SkillValue, SkillValueData } from '../interfaces';

interface CategorySelectedIconsProps {
  selectionMap: Immutable.Map<
    string,
    Immutable.Map<string, SkillValueData>
  >;
}

function CategorySelectedIcons({
  selectionMap,
}: CategorySelectedIconsProps) {
  const [skillArray, setSkillArray] = useState([]);
  const [grayscale, setGrayscale] = useState('');

  useEffect(() => {
    const selectionsArray: SkillValue[] = Array(6).fill(
      SkillValue.Empty,
      0
    );
    selectionMap.forEach((skillMap) => {
      skillMap.forEach((skill) => {
        if (skill && skill.skillValue) {
          selectionsArray[skill.skillValue.toString()] =
            skill.skillValue;
          if (skill.skillValue === -1) {
            setGrayscale('grayscale');
          }
        }
      });
    });
    setSkillArray(selectionsArray); // sets value if any subskill has this skillValue
  }, [selectionMap]);

  return (
    <div className="category-selected-positioning">
      {skillArray.map((selectedLevel, i) => {
        return (
          <div
            key={i}
            className={`category-selected-icon category-level level-${skillArray[i]} ${grayscale}`}
          >
            {i}
          </div>
        );
      })}
    </div>
  );
}

export default CategorySelectedIcons;
