import React from 'react';

interface CandidateDataDisplayProps {
  data: Object;
}

const CandidateDataDisplay = ({
  data,
}: CandidateDataDisplayProps) => {
  if (!data) {
    return (
      <div className="candidate-data-display">
        <p>No Data</p>
      </div>
    );
  }
  let content = [];
  console.log(data);

  const created = data['created_at'].substring(0, 10);
  const updated = data['updated_at'].substring(0, 10);
  const customerName = data['customer']['name'];
  const contactName = data['contact']['name'];
  const postcode = data['postcode'];

  return (
    <div className="candidate-data-display">
      <h2>{customerName}</h2>
      <h2>{contactName}</h2>
      <h2>Postcode {postcode}</h2>
      <p>Updated {created}</p>
      <p>Created {created}</p>
    </div>
  );
};

export default CandidateDataDisplay;
