import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { redirect as Redirect } from 'react-router';
import { Navigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import * as server from '../api/skills-backend';
import { deleteCookies } from '../helpers/storage';

const LogoutButton = () => {
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const successMessage = 'You have been logged out successfully.';
  const serverStatus = useSelector(({ logout }) => {
    return logout.serverStatus;
  });
  const terminateAuthToken = async () => {
    // await is required here, otherwise the auth token will be deleted before server post is sent

    try {
      // const token = localStorage.getItem('skills-auth-token');
      const response = await server.default.instance.post('logout', {
        // headers: { Authorization: token ? `Bearer ${token}` : '' },
      });
      const status = response.status;
      dispatch({ type: 'logout/serverStatus', payload: status });
      switch (status) {
        case 200: {
          dispatch({
            type: 'logout/success',
            payload: successMessage,
          });
          break;
        }
        default:
          dispatch({
            type: 'logout/success',
            payload: `You have been logged out with server status ${status}`,
          });
      }
      deleteCookies();
      localStorage.clear();
    } catch (error) {
      console.error('Failed to terminate auth token', error.message);
      dispatch({
        type: 'logout/setErrorObj',
        payload: error,
      });
    }
  };

  //   server.default.instance
  //     .post('logout')
  //     .then((response) => {
  //       console.info('Server response', response);
  //       const status = response.status;
  //       dispatch({ type: 'logout/serverStatus', payload: status });
  //       switch (status) {
  //         case 200: {
  //           dispatch({
  //             type: 'logout/success',
  //             payload: successMessage,
  //           });
  //           break;
  //         }
  //         default:
  //           dispatch({
  //             type: 'logout/success',
  //             payload: `You have been logged out with server status ${status}`,
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch({
  //         type: 'logout/setErrorObj',
  //         payload: error,
  //       });
  //     });
  // };

  const signOutUser = () => {
    terminateAuthToken();
    try {
      dispatch({ type: 'LOG_OUT' }); // This is the action that resets the state
      console.log('Logging out. Redirecting...');
      setRedirect(true);
    } catch (error) {
      console.error('Error in logging out', error);
    }
  };

  if (redirect) {
    setRedirect(false);
    return <Navigate to="/" />;
  }

  return (
    <Button
      className="logout-button"
      disabled={serverStatus === '' ? false : true}
      onClick={() => signOutUser()}
    >
      <p>Logout</p>
    </Button>
  );
};

export default LogoutButton;
