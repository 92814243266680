import React, { useState } from 'react';
import { Button, Input, Segment } from 'semantic-ui-react';

interface AddNewCapabilityProps {
  onClick: () => void;
  onChange: (newCapabilityName: string) => void;
}

interface AddNewCapabilityState {
  addView: Boolean;
}

const AddNewCapability = ({
  onClick,
  onChange,
}: AddNewCapabilityProps) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState('');
  return (
    <>
      {showInput ? (
        <div className="input-new-capability">
          <Segment>
            <div className="title-new-skill">
              <h4>Type name of your new skill</h4>
              <Button circular icon="cancel" />
            </div>
            <Input
              onChange={(e) => setNewValue(e.currentTarget.value)}
            ></Input>
          </Segment>
        </div>
      ) : null}
      <div className="add-new-capability">
        <Button
          fluid
          onClick={
            newValue.length === 0
              ? () => {
                  onClick();
                  setShowInput(true);
                }
              : () => onChange(newValue)
          }
        >
          {`Add capability ${newValue}`}
        </Button>
      </div>
    </>
  );
};

export default AddNewCapability;
