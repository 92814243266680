import { combineReducers } from '@reduxjs/toolkit';
import userdataSlice from './userdataSlice';
import loginSlice from './loginSlice';
import searchSlice from './searchSlice';
import logoutSlice from './logoutSlice';

const appReducer = combineReducers({
  userdata: userdataSlice,
  login: loginSlice,
  search: searchSlice,
  logout: logoutSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
