import { sendSkillValueToServer } from '../handlers/skillValueHandlers';
import { Skill, SkillValue, SkillValueData } from '../interfaces';
import { Seq, Map, fromJS, updateIn } from 'immutable';

/**
 * Sorts skill categories in ascending alphabetical order
 * @param categories Skill array that describes categories
 * @returns Skill array sorted in ascending alphabetical order
 */
const sortCategoriesByName = (categories: Skill[]): Skill[] => {
  if (categories) {
    const sortedArray = []
      .concat(categories)
      .sort((a, b) => a.name.localeCompare(b.name));
    return sortedArray;
  }
  // console.error('sortCategoriesByName array undefined!');
  return [];
};

/**
 * Sort skills in alphabetic ascending order
 * @param skillTree Array of Skills must have a name property
 * @returns Alphabetically ascending sorted array of Skills
 */
const sortSkillsByName = (skillTree: Skill[]): Skill[] => {
  if (skillTree) {
    const sortedArray = []
      .concat(skillTree)
      .sort((a, b) => a.name.localeCompare(b.name));
    return sortedArray;
  }
  console.error('sortSkillsSByName array undefined!');
  return [];
};

/**
 * Remove skills that have a default skillValue of -1 in a frontend sequence
 * @param sequenceWithDefaults Contains skillValues of value -1
 * @returns Immutable map of skills that do not contain skillValues of -1
 */
const removeDefaultSkills = (
  sequenceWithDefaults: Map<string, Map<string, SkillValueData>>
) => {
  const sequenceIter = sequenceWithDefaults.map((value, key) => {
    if (typeof value === 'object') {
      return value.filter((skillData) => {
        return skillData['skillValue'] != SkillValue.Empty; // if skill value is not -1, returns true
      });
    }
  });
  return sequenceIter;
};

const fromSkillTreetoSubskillMap = (skillTree: Skill) => {
  try {
    const categoryName = skillTree.name;
    let subskillObj: Map<string, SkillValueData> = Map();
    (skillTree.children || []).forEach(
      ({ name, id, path, skillValue }) => {
        const skillValueDataEntry: SkillValueData = {
          skillId: id,
          skillPath: path,
          skillValue: skillValue ?? SkillValue.Empty,
        };
        subskillObj = subskillObj.set(name, skillValueDataEntry);
      }
    );
    let immutableSubskillsMap: Map<
      string,
      Map<string, SkillValueData>
    > = Map();
    immutableSubskillsMap = immutableSubskillsMap.set(
      categoryName,
      subskillObj
    );
    return immutableSubskillsMap;
  } catch (error) {
    console.error(
      'Converting Immutable subskills map failed',
      error.message
    );
  }
};

/**
 * Callback that handles skill level change in skill-category
 * @param subskills
 * @param setSubskills
 * @param setSkillDataEntry
 * @param skillReadableName Human readable skill key property 'name'
 * @param skillData
 * @param newValue
 * @param shouldUpdateServer
 * @param setOnMapChange
 */
const onSkillLevelChange = (
  subskills: Map<string, Map<string, SkillValueData>>,
  setSubskills: Function,
  setSkillDataEntry: Function,
  skillReadableName: string,
  skillData: SkillValueData,
  newValue,
  shouldUpdateServer: Boolean,
  setShouldChangeMap: Function
) => {
  console.group('onSkillLevelChange');
  console.debug('shouldUpdateServer? ', shouldUpdateServer);
  console.debug(
    `Subskill ${skillReadableName} changed! Its new value is ${newValue}!`
  );
  console.debug(`Existing skillData`, skillData);
  console.groupEnd();

  let keyName = subskills.keySeq().toArray() || [];
  let skillDataEntry: SkillValueData;
  setSubskills(
    subskills.updateIn([keyName[0], skillReadableName], () => {
      skillDataEntry = {
        ...skillData,
        skillValue: newValue,
      };
      return skillDataEntry;
    })
  );
  setSkillDataEntry(skillDataEntry);
  setShouldChangeMap(true);
};

export {
  sortCategoriesByName,
  sortSkillsByName,
  removeDefaultSkills,
  fromSkillTreetoSubskillMap,
  onSkillLevelChange,
};
