import React from 'react';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import { Skill, ProfileData, SkillValueData } from '../interfaces';
import SkillCategory from './skill-category';
import { sortCategoriesByName } from '../helpers/skills';

interface FillAreaProps {
  onMapChange: (
    map: Map<string, Map<string, SkillValueData>>
  ) => void;
  profileData: ProfileData;
  serverId: string;
  shouldUpdateServer: Boolean;
}

const FillArea = ({
  onMapChange,
  profileData,
  serverId,
  shouldUpdateServer,
}: FillAreaProps) => {
  const authToken = useSelector(({ login }) => {
    return login.authToken;
  });

  return (
    <div className="selection-area">
      <div className="categories">
        {sortCategoriesByName(profileData?.skillTree?.children).map(
          (skill: Skill, i: number) => (
            <SkillCategory
              authToken={authToken}
              key={i.toString()}
              onMapChange={onMapChange}
              serverId={serverId}
              profileData={{ ...profileData, skillTree: skill }}
              shouldUpdateServer={shouldUpdateServer}
            />
          )
        ) ?? 'Missing skill data from server.'}
      </div>
    </div>
  );
};

export default FillArea;
