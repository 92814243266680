import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { getDefaultSkillTree } from '../handlers/skillDataHandlers';
import { computeCandidateScores } from '../algorithms/compute-scores';
import { computeTotalPoints } from '../helpers/scoring';
import CandidateResults from './candidate-results';
import FillArea from './fill-area';
import {
  Candidate,
  CandidateData,
  ExternalType,
  ProfileData,
  SkillValueData,
} from '../interfaces';

const initialMap = Map<string, Map<string, SkillValueData>>({});

const FindConsultant = () => {
  const [findMatchMap, setFindMatchMap] = useState(initialMap);
  const dispatch = useDispatch();

  // Selectors
  const candidates: Candidate[] = useSelector(({ search }) => {
    return search.candidates;
  });

  const personsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData.profiles;
  });

  const candidateSearchDefaultsData = useSelector(({ search }) => {
    return search.searchDefaults;
  }) as ProfileData;
  // ---

  // Manage cached userdata loading here EM210518
  useEffect(() => {
    const cachedUserdata = localStorage.getItem('skills-personData');
    // console.debug('cachedUserdata: ', cachedUserdata);
    // Cached data is not used if userdata has already been updated, or if there is no data in cache
    if (personsData.length === 0 && cachedUserdata) {
      dispatch({
        type: 'userdata/initBatchOfProfiles',
        payload: JSON.parse(cachedUserdata),
      });
    }
  }, []);
  /*   In Zendesk, 16856 is just created and this URL is accessed in skills.pepron.com and edit lead view is presented
  If user is agent then agent-link is openede to ZD
  Used to look like this "https://server1.pepron.com/pepron-skills/lead.php?link=6020e64c44173-16856" */

  // Run first time only
  useEffect(() => {
    console.log('Candidates ', candidates);
    console.log('CandidatefaultsData ', candidateSearchDefaultsData);
    if (candidates.length > 0 && candidates[0].score === undefined) {
      dispatch({ type: 'search/initCandidateScores' });
    }
    getDefaultSkillTree()
      .then((defaultSkillTree) => {
        console.log('Got defaultSkillTree ', defaultSkillTree);
        dispatch({
          type: 'search/initDefaultSkills',
          payload: defaultSkillTree,
        });
      })
      .then(() => {
        console.log('Candidates ', candidates);
        console.log('personsData', personsData);
        console.log(
          'CandidateDefaultData ',
          candidateSearchDefaultsData
        );
      });
  }, []);
  // Total points computation
  let totalPoints = computeTotalPoints(findMatchMap);

  useEffect(() => {
    console.log('Computing scores...', findMatchMap);
    dispatch({ type: 'search/resetScores' });
    const scoringActions = computeCandidateScores(
      personsData,
      findMatchMap,
      'search/addScorePoints'
    );
    for (let action of scoringActions) {
      dispatch(action);
    }
  }, [findMatchMap]);

  return (
    <div className="leads-view">
      <div className="guideline">
        <p>
          Select required skills and search for personnel profiles
        </p>
      </div>
      <div className="fill-area-container">
        {candidateSearchDefaultsData ? (
          <FillArea
            serverId={null}
            profileData={candidateSearchDefaultsData}
            onMapChange={(
              map: Map<string, Map<string, SkillValueData>>
            ) => {
              console.log('match map', findMatchMap);
              const newMapState = findMatchMap.mergeDeep(map);
              console.log('new map', newMapState);
              setFindMatchMap(newMapState);
            }}
            shouldUpdateServer={false}
          />
        ) : (
          <p>Waiting....</p>
        )}
      </div>
      <div className="candidate-results-container">
        {personsData ? (
          <CandidateResults
            candidatesScores={candidates}
            candidatesDataful={personsData}
            externalType={ExternalType.Person}
            totalPoints={totalPoints}
          />
        ) : (
          <p>Waiting...</p>
        )}
      </div>
    </div>
  );
};

export default FindConsultant;
