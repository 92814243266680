import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';
import { Outlet } from 'react-router-dom';

// interface LayoutProps {
//   children: React.PropsWithChildren<JSX.Element>;
// }

const Layout = () => {
  return (
    <div id="content-wrap">
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
