import { Skill, CandidateData, Candidate } from '../interfaces';
import * as server from '../api/skills-backend';
import { Dispatch } from 'redux';
import {
  getLeadProfilesData,
  getPersonProfilesData,
} from '../handlers/searchDataHandlers';

/**
 * Returns profile info, different depending on ExternalType
 * @param externalId
 * @returns profileInfo fields, or undefined if not found
 */
const getLeadProfileInfo = async (externalId: string) => {
  let response;
  try {
    response = await server.default.get(
      // what is the type returned by lead profile API request? EM210527
      `lead/${externalId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      'Cannot fetch profile info for lead to be edited!',
      error.message
    );
  }
  return undefined;
};

/**
 * Returns property of profile 'profile.skillTree'
 * @param serverId uuid string
 * @returns skillTree that is of type Skill, or void if error
 */
const getLeadSkillTree = async (serverId: string): Promise<Skill> => {
  // AxiosPromise<Skill> return type reports error as of 21.05.21, which is a bug, this is workaround
  if (!serverId) {
    console.error(`serverId missing!`);
    return;
  }
  let response;
  try {
    response = await server.default.get(`profile/${serverId}/skill`);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

const updateLoadedProfiles = async (
  dispatch: Dispatch,
  candidates: Candidate[]
) => {
  dispatch({
    type: 'userdata/setLoadingProfiles',
    payload: 'loading',
  });
  let personProfilesData: CandidateData[];
  try {
    // leads are filtered out
    // personProfilesData will contain profileInfos for ease of access
    personProfilesData = await getPersonProfilesData(candidates);
    dispatch({
      type: 'userdata/addBatchOfProfiles',
      payload: personProfilesData,
    });
    console.info('...updated persons!');
    localStorage.setItem(
      'skills-personData',
      JSON.stringify(personProfilesData)
    );
  } catch (errors) {
    console.error(
      'Errors: Could not update person profiles, see error messages! Aborting person profile updates.',
      errors
    );
  }

  let leadProfilesData: CandidateData[];
  try {
    leadProfilesData = await getLeadProfilesData(candidates);
    dispatch({
      type: 'userdata/addBatchOfLeads',
      payload: leadProfilesData,
    });
    console.info('...updated leads!');
    // Add data cache to localStorage
    localStorage.setItem(
      'skills-leadData',
      JSON.stringify(leadProfilesData)
    );
  } catch (errors) {
    console.error(
      'Errors: could not update lead profiles, see error messages! Aborting lead profile updates.',
      errors
    );
  }
  // Finally
  dispatch({
    type: 'userdata/setLoadingProfiles',
    payload: '',
  });
};

export { getLeadProfileInfo, getLeadSkillTree, updateLoadedProfiles };
