import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CandidateInfo,
  ExternalType,
  ProfileData,
} from '../interfaces';
import FillArea from './fill-area';
import Instructions from './instructions';
import { getMyInitialSkills } from '../handlers/skillDataHandlers';

const MainView = () => {
  const serverId = useSelector(({ login }) => {
    return login.serverId;
  });
  const myData = useSelector(({ userdata }) => {
    return userdata.myData;
  });
  const myInfo = useSelector((state: any) => {
    return state.login.externalData?.profile;
  }) as CandidateInfo;
  const myProfileData: ProfileData = {
    skillTree: myData?.skillTree,
    externalId: myInfo?.id,
    externalType: ExternalType.Person,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const initSkills = async () => {
      const myInitialSkills = await getMyInitialSkills(serverId);
      dispatch({
        type: 'userdata/addMyProfile',
        payload: myInitialSkills,
      });
    };
    initSkills();

    // TODO don't run get initial skills if data is kept in redux store
  }, []);

  return (
    <div className="skills-view">
      <Instructions />
      <FillArea
        onMapChange={() => {}}
        profileData={myProfileData}
        serverId={serverId}
        shouldUpdateServer={true}
      />
    </div>
  );
};

export default MainView;
