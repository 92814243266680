import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CandidateData } from '../interfaces';
import { useSelector } from 'react-redux';
import { findCandidateWithExtId } from '../helpers/candidates';
import FillArea from './fill-area';

const ConsultantProfile = () => {
  let params = useParams();
  const [candidateInfo, setCandidateInfo] =
    useState<CandidateData | null>(null);
  // Selectors
  const personsData: CandidateData[] = useSelector(
    (state: {
      userdata: { usersData: { profiles: CandidateData[] } };
    }) => {
      return state.userdata.usersData.profiles;
    }
  );

  useEffect(() => {
    console.log('params', params);
    const data = findCandidateWithExtId(params.id, personsData);
    console.log('Consultant data is ', data);
    setCandidateInfo(data);
  }, [params, personsData]);

  return (
    <div className="consultant-wrapper">
      <h1 className="consultant-title">Consultant Profile</h1>
      {candidateInfo && candidateInfo.profileInfo && (
        <div className="consultant-info">
          <h2>{candidateInfo.profileInfo.name || 'N/A'}</h2>
          <img
            src={candidateInfo.profileInfo.photo.toString()}
            height="80"
          />
          <p>ID: {candidateInfo.profileInfo.id || 'N/A'}</p>
          <p>Role: {candidateInfo.profileInfo.role || 'N/A'}</p>
          <p>
            Available:
            {' ' + candidateInfo.profileInfo.available || 'N/A'}
          </p>
          <p>
            Postcode: {candidateInfo.profileInfo.postcode || 'N/A'}
          </p>
        </div>
      )}
      {candidateInfo && candidateInfo.profile && (
        <FillArea
          onMapChange={() => {
            return;
          }}
          profileData={candidateInfo.profile}
          serverId={null}
          shouldUpdateServer={false}
        />
      )}
    </div>
  );
};

export default ConsultantProfile;
