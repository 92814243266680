export const getCookieValue = (cname: string) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (
  cookieName: string,
  cookieValue: string
): void => {
  document.cookie = `${cookieName}=${cookieValue};max-age=86400;SameSite=Strict;Secure;Path=/`;
  // document.cookie = `skills-auth-token=${authToken};max-age=86400;domain=localhost`;
};

export const deleteCookies = (): void => {
  document.cookie =
    'skills-auth-token=; expires=Thu, 21 Oct 2000 00:00:00 GMT';
  document.cookie =
    'skills-my-role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
};

export const setLocalStorage = (item: any): void => {
  const storage = window.localStorage;
  storage.setItem('skills-auth-token', item);
};
