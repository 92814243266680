import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Option } from '../../interfaces';

interface DropdownSelectProps {
  onOptionChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
  placeholderText: string;
  options: Option[];
}

const DropdownSelect = ({
  options,
  onOptionChange,
  placeholderText,
}: DropdownSelectProps) => {
  return (
    <div className="dropdown-select">
      <Dropdown
        closeOnChange
        placeholder={placeholderText ?? 'Select candidate'}
        fluid
        selection
        onChange={onOptionChange}
        options={options}
      />
    </div>
  );
};

export default DropdownSelect;
