import {
  Candidate,
  Skill,
  ExternalType,
  CandidateData,
  CandidateInfo,
  SkillValue,
  FillLevel,
} from '../interfaces';
import * as server from '../api/skills-backend';
import { AxiosPromise, AxiosResponse } from 'axios';

const getCandidateInfo = async (
  externalId: string
): Promise<CandidateInfo> => {
  console.debug('getCandidateInfo started...');
  let response;
  try {
    response = await server.default.instance.get(
      `person/${externalId}`
    );
  } catch (error) {
    console.error(
      `Could not fetch profileInfo for user id ${externalId}`,
      error.message
    );
  }
  console.debug('...loaded getCandidateInfo');
  return response.data;
};

/* const getAllCandidateInfos = async (candidates: Candidate[]) => {
  const infosArr: CandidateInfo[] = [];
  for (let candidate of candidates) {
    const profileInfo = await getCandidateInfo(candidate.externalId);
    infosArr.push(profileInfo);
  }
  return infosArr;
}; */

const getLeadProfilesData = async (candidates: Candidate[]) => {
  // 0. Filter persons out
  const leadCandidates = candidates.filter((candidate) => {
    return candidate.externalType === ExternalType.Lead;
  });
  let leadProfilesData: CandidateData[] = [];
  const leadProfilesToFetch: AxiosPromise<any>[] = [];

  // 1. Init leadProfilesData array
  for (let candidate of leadCandidates) {
    leadProfilesData.push({
      ...candidate,
      profileInfo: null,
      profile: {
        skillTree: null,
        externalType: candidate.externalType,
        externalId: candidate.externalId,
      },
    });
  }

  // 2. Setup leadProfilesToFetch array
  for (let candidate of leadCandidates) {
    leadProfilesToFetch.push(
      server.default.instance.get(
        `profile/${candidate.serverId}/skill`
      )
    );
  }

  // 3. Fetch lead profiles in parallel using Promise.all, put result in leadProfilesData
  // console.group('Start lead profiles updating...');
  let loadedLeads;
  try {
    loadedLeads = await Promise.all(leadProfilesToFetch);
  } catch (errors) {
    console.error(errors);
    throw errors;
  }
  for (let i = 0; i < loadedLeads.length; i++) {
    leadProfilesData[i].profile.skillTree = loadedLeads[i].data;
  }
  // console.groupEnd();
  return leadProfilesData;
};

/**
 * getPersonProfilesData returns array of person profiles which include a skill tree and person information
 * @param candidates Accepts all type of candidates, filters and works only with ExternalType = Person
 * @returns CandidateData[]
 */
const getPersonProfilesData = async (candidates: Candidate[]) => {
  console.log('Getting persons profiles data...');
  // 0. Filter leads out
  const personCandidates = candidates.filter((candidate) => {
    return candidate.externalType === ExternalType.Person;
  });
  let personProfilesData: CandidateData[] = [];
  const profilesToFetch: Promise<AxiosResponse<Skill>>[] = [];
  const infosToFetch: Promise<AxiosResponse<CandidateInfo>>[] = [];

  // 1. Init personProfilesData array
  for (let candidate of personCandidates) {
    personProfilesData.push({
      ...candidate,
      profileInfo: null,
      profile: {
        skillTree: null,
        externalType: candidate.externalType,
        externalId: candidate.externalId,
      },
    });
  }

  // 2. Setup profilesToFetch array | this is skillTree GET
  for (let candidate of personCandidates) {
    profilesToFetch.push(
      server.default.instance.get(
        `profile/${candidate.serverId}/skill`
      )
    );
  }

  // 3. Setup infosToFetch array
  for (let candidate of personCandidates) {
    infosToFetch.push(
      server.default.instance.get(`person/${candidate.externalId}`)
    );
  }

  // 4. Fetch profiles in parallel using Promise.all, put result in personProfilesData
  let loadedPersonSkilltrees;
  let loadedPersonInfos;
  try {
    loadedPersonSkilltrees = await Promise.all(profilesToFetch);
    loadedPersonInfos = await Promise.all(infosToFetch);
  } catch (errors) {
    console.error(errors);
    throw errors;
  }

  for (let i = 0; i < loadedPersonSkilltrees.length; i++) {
    personProfilesData[i].profile.skillTree =
      loadedPersonSkilltrees[i].data;

    // 5. Iterate and set the fill level of profile's skills
    let fillLevel = null;
    personProfilesData[i].profile.skillTree?.children.map(
      (category, j) => {
        category.children.map((subskill: Skill, k) => {
          if (
            !subskill ||
            !subskill.hasOwnProperty('skillValue') ||
            subskill.skillValue === SkillValue.Empty
          ) {
            fillLevel = FillLevel.Partial;
          }
        });
      }
    );
    if (!fillLevel) fillLevel = FillLevel.Full;
    personProfilesData[i].profile.fillLevel = fillLevel;
  }
  for (let i = 0; i < loadedPersonInfos.length; i++) {
    personProfilesData[i].profileInfo = loadedPersonInfos[i].data;
  }

  return personProfilesData;
};

const getSearchCandidates = async (token: string) => {
  console.log('getSearchCandidates started...');
  let response;
  try {
    // Authorization header is set here, because Axios interceptors are not set up for this request yet!
    response = await server.default.instance.get('profile', {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    });
  } catch (error) {
    console.error(
      `Failed to get search candidates ids!`,
      error.message
    );
  }
  console.info('Updated search pool candidates.', response);
  const candidates: Candidate[] = response?.data;
  return candidates;
};

const getSkillTreeData = async (candidate: Candidate) => {
  let response;
  try {
    response = await server.default.instance.get(
      `profile/${candidate.serverId}/skill`
    );
  } catch (error) {
    console.error(
      `Failed to get candidate data (for ${JSON.stringify(
        candidate
      )})`,
      error.message
    );
  }

  const skillTreeData: Skill = response?.data;
  return skillTreeData;
};

export {
  getSearchCandidates,
  getSkillTreeData,
  getLeadProfilesData,
  getPersonProfilesData,
  getCandidateInfo,
};
