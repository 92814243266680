import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import CandidateDataDisplay from './candidate-data-display';
import { computeCandidateScores } from '../algorithms/compute-scores';
import { toSequence } from '../algorithms/fromSkillTreeToSequence';
import { computeTotalPoints } from '../helpers/scoring';
import { findCandidateServerIdWithExtId } from '../helpers/candidates';
import {
  getLeadSkillTree,
  getLeadProfileInfo,
} from '../helpers/profiles';
import CandidateResults from './candidate-results';
import FillArea from './fill-area';
import {
  AccessType,
  Candidate,
  CandidateData,
  CandidateInfo,
  ExternalType,
  LocationState,
  MatchPathState,
  ProfileData,
  Skill,
  SkillValueData,
} from '../interfaces';

const initialMap = Map<string, Map<string, SkillValueData>>({});

const LeadEdit = () => {
  const [extId, setExtId] = useState('');
  const [serverId, setServerId] = useState('');
  const [skillTree, setSkillTree] = useState<Skill>(null);
  const [sequence, setSequence] =
    useState<Map<string, Map<string, SkillValueData>>>(initialMap);
  const [profileInfo, setProfileInfo] = useState(null);
  const [profileData, setProfileData] = useState<ProfileData>(null);
  const [myLeadData, setMyLeadData] = useState<CandidateData>(null);
  const [error, setError] = useState<Error>(null);
  const NOT_FOUND_MESSAGE = (
    <div className="not-found">
      <p>{`Data for candidate ${extId} was not found`}</p>
    </div>
  );
  const LOADING_PROFILE_DATA = 'Loading profile data...';

  const dispatch = useDispatch();
  const location = useLocation();
  // selectors
  const candidates: Candidate[] = useSelector(({ search }) => {
    return search.candidates;
  });
  const leadEditScores: Candidate[] = useSelector(({ search }) => {
    return search.leadEditScores;
  });
  const personsData = useSelector(({ userdata }) => {
    return userdata.usersData.profiles;
  });

  // initialises profile data
  useEffect(() => {
    if (skillTree && serverId.length > 0 && !profileData) {
      console.group('useEffect: skillTree');
      console.log('skillTree', skillTree);
      console.log('serverId', serverId);
      console.log('profileData', profileData);
      console.groupEnd();
      // Update profileData
      // Here we put serverId as externalId, because
      // PUT skills API request inside FillArea will use serverId to send data to server
      const newProfileData = {
        skillTree: skillTree,
        externalId: serverId,
        externalType: ExternalType.Lead,
      };

      setProfileData(newProfileData);

      // Get profileInfo and then create a sequence
      console.group('Start sequence processing...');
      console.log('is external id missing or not? ', extId);
      getLeadProfileInfo(extId).then((profileInfo) => {
        setProfileInfo(profileInfo);
        console.log('profile info: ', profileInfo);

        // Build a single lead data structure without automated build script
        const myLeadData = {
          profileInfo: profileInfo,
          profile: {
            skillTree: skillTree,
            externalId: extId,
            externalType: ExternalType.Lead,
          },
          serverId: serverId,
          externalId: extId,
          externalType: ExternalType.Lead,
          access: AccessType.Edit,
        };
        setMyLeadData(myLeadData);

        // Create a sequence from CandidateData
        const initialSequence = toSequence(myLeadData);
        console.log(
          `Checking that initialSequence is actually correct, here it is: `,
          initialSequence
        );
        setSequence(initialSequence); // all data should be ready by this point
        console.groupEnd();
      });
    }
  }, [skillTree]); // Run only after skillTree data is ready for interpreting EM210527

  useEffect(() => {
    // this rerenders the component when profileInfo updates, displays info before profiles and leads finish loading
  }, [profileInfo]);

  useEffect(() => {
    if (candidates.length > 0) {
      console.info('useEffect! candidates');
      // 1. Init lead edit search, once only, copies only persons and sets zero scores'
      if (candidates.length > 0 && leadEditScores[0] === undefined) {
        dispatch({ type: 'search/initLeadEditScores' });
      }

      // 2. Extract externalId
      const match: MatchPathState = matchPath(
        {
          path: '/lead/:externalId',
        },
        location.pathname
      );
      const extIdFromMatchPath = match.params.externalId;
      setExtId(extIdFromMatchPath);

      // 3. Get lead profile info
      /* getLeadProfileInfo(extIdFromMatchPath).then((profileInfo) => {
        setProfileInfo(profileInfo);
      }); */

      // 4. Get server id, handle error if undefined
      const uuid = findCandidateServerIdWithExtId(
        extIdFromMatchPath,
        candidates
      );
      if (!uuid) {
        // external id does not exist on server
        setError(new Error('Lead not found'));
      }
      setServerId(uuid);

      // 5. Get lead skillTree, set skillTree
      getLeadSkillTree(uuid).then((skillTree: Skill) => {
        setSkillTree(skillTree);
      });
      // 6. The end
      // after skillTree is set, another effect will work with it
    }
  }, [candidates]);

  // Total points computation
  let totalPoints = computeTotalPoints(sequence);

  // Run only when skill sequence changes
  useEffect(() => {
    console.info('useEffect! sequence');
    // 1. Reset scores before new computation
    dispatch({ type: 'search/resetLeadEditScores' });
    // 2. Compute scores for persons
    console.log(
      '2. Compute scores for persons: sequence: ',
      sequence
    );
    const scoringActions = computeCandidateScores(
      personsData,
      sequence,
      'search/addLeadEditScorePoints'
    );
    // 3. Dispatch changes to store
    for (let action of scoringActions) {
      dispatch(action);
    }
  }, [sequence]);

  const leadid = profileInfo && profileInfo?.id;
  const title = error
    ? error.message
    : profileInfo && profileInfo.name
      ? profileInfo.name
      : 'Waiting...';
  const profileDataOptional = error
    ? NOT_FOUND_MESSAGE
    : LOADING_PROFILE_DATA;

  return (
    <div className="lead-edit">
      <div className="lead-title">
        <h1>
          <a
            href={
              'https://pdi-pepron.zendesk.com/agent/tickets/' + leadid
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            #{leadid}
          </a>
          &nbsp;{title}
        </h1>
      </div>
      <div className="description">
        Edit lead and select required skills. Lead skills are
        initialised with up-to-date values.
      </div>
      <div className="profile-information-container">
        <div className="contact-and-customer">
          <CandidateDataDisplay data={profileInfo} />
        </div>
      </div>
      <div className="results">
        <CandidateResults
          candidatesScores={leadEditScores}
          candidatesDataful={personsData}
          externalType={ExternalType.Person}
          totalPoints={totalPoints}
        />
      </div>
      <div className="lead-edit-selection-area">
        {profileData?.skillTree ? (
          <FillArea
            serverId={serverId}
            profileData={profileData}
            onMapChange={(map) => {
              const newMapState = sequence.mergeDeep(map);
              setSequence(newMapState);
            }}
            shouldUpdateServer={true}
          />
        ) : (
          profileDataOptional
        )}
      </div>
    </div>
  );
};

export default LeadEdit;
