import { Candidate, CandidateData } from '../interfaces';

/**
 * Returns serverId using externalId
 * @param extId externalId
 * @param candidates Array of candidates
 * @returns serverId or undefined if not found
 */
const findCandidateServerIdWithExtId = (
  extId: string,
  candidates: Candidate[]
): string | undefined => {
  const foundCandidate = findCandidateWithExtId(
    extId,
    candidates as CandidateData[]
  );
  if (foundCandidate) {
    return foundCandidate.serverId;
  }
  console.error(`Data for candidate ${extId} was not found.`);
  return undefined;
};

/**
 * Find a candidate name using externalId
 * @param extId Uses externalId to get name
 * @param candidatesWithProfileInfo Array of candidates must have property profileInfo.name
 * @returns Name, or undefined if it was not found
 */
const findCandidateNameWithExtId = (
  extId: string,
  candidatesWithProfileInfo: CandidateData[]
): string => {
  const foundCandidate: CandidateData = findCandidateWithExtId(
    extId,
    candidatesWithProfileInfo
  );

  if (!foundCandidate) {
    console.error(`Data for candidate ${extId} was not found.`);
    return undefined;
  }

  let info = null;
  let name = '';
  try {
    info = foundCandidate.profileInfo;
  } catch (error) {
    console.error(
      `Match was found, but candidate ${extId} does not have profileInfo.`
    );
    return undefined;
  }
  try {
    name = info.name;
  } catch (error) {
    console.error(
      `Match was found, and profileInfo was found for ${extId} but it does not include a name!`
    );
    return undefined;
  }
  return name;
};

/**
 * Find a candidate with data using externalId
 * @param extId Uses externalId to match candidate
 * @param candidates Array of candidates who have property externalId
 * @returns Found candidate or undefined
 */
const findCandidateWithExtId = (
  extId: string, // it is possible that this type is cast to e.g. number when executing EM210527
  candidates: CandidateData[]
): CandidateData | undefined => {
  const result = candidates.find(
    (candidate) => '' + candidate.externalId === '' + extId // ugly but fastest type cast, it breaks without forced typecast EM210527
  );
  return result;
};

/**
 * Sort candidates in descending score order
 * @param candidatesWithScores must include existing score property
 * @param passRenderFunction function that returns JSX for rendering
 * @returns Numerically ascending sorted array of Candidates
 */
const sortCandidatesByScore = (
  candidatesWithScores: Candidate[]
): Candidate[] => {
  return []
    .concat(candidatesWithScores)
    .sort((a, b) => (a.score < b.score ? 1 : -1));
};

/**
 * Sort candidates in ascending name order alphabetically
 * @param candidatesWithName must include existing profileInfo.name property
 * @returns Alphabetically ascending sorted array of Candidates
 */
const sortCandidatesByName = (
  candidatesWithName: CandidateData[]
): CandidateData[] => {
  return []
    .concat(candidatesWithName)
    .sort((a, b) =>
      a.profileInfo.name.localeCompare(b.profileInfo.name)
    );
};

export {
  findCandidateServerIdWithExtId,
  findCandidateNameWithExtId,
  findCandidateWithExtId,
  sortCandidatesByScore,
  sortCandidatesByName,
};
