import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <p>Pepron 2021 | Skills</p>
    </div>
  );
};

export default Footer;
