import React from 'react';
import { Dimmer, Loader, Transition } from 'semantic-ui-react';

interface SpinnerPreparingProps {
  what: string;
  visible: boolean;
}

const SpinnerPreparing = ({
  what,
  visible,
}: SpinnerPreparingProps) => {
  return (
    <Transition visible={visible} animation="scale" duration={500}>
      <Dimmer active={visible}>
        <Loader indeterminate>{`Updating ${what}`}</Loader>
      </Dimmer>
    </Transition>
  );
};

export { SpinnerPreparing };
