import React, { SyntheticEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { SkillValue } from '../interfaces';

interface SkillSelectionProps {
  initialValue: SkillValue;
  onSkillValueChange: (level: SkillValue) => void;
}

const SkillSelection = ({
  initialValue,
  onSkillValueChange,
}: SkillSelectionProps) => {
  const [skillLevel, setSkillValue] = useState(initialValue);
  const getLevelClass = (level: number): string => {
    return skillLevel === level ? `level-${level}` : 'level--1';
  };
  const location = useLocation();
  function skillOnClick(level: SkillValue) {
    console.log('location', location);
    if (
      location &&
      location.pathname?.startsWith('/consultant') // consultant view is read-only
    )
      return;
    setSkillValue(level);
    onSkillValueChange(level);
  }

  return (
    <div className="skill-buttons">
      <Button
        className={`skill-level ${getLevelClass(
          SkillValue.NoUnderstanding
        )}`}
        title="No understanding"
        onClick={(event: SyntheticEvent) =>
          skillOnClick(SkillValue.NoUnderstanding)
        }
      ></Button>
      <Button
        className={`skill-level ${getLevelClass(
          SkillValue.Beginner
        )}`}
        title="Beginner"
        onClick={(event: SyntheticEvent) =>
          skillOnClick(SkillValue.Beginner)
        }
      ></Button>
      <Button
        className={`skill-level ${getLevelClass(SkillValue.Amateur)}`}
        title="Amateur"
        onClick={(event: SyntheticEvent) =>
          skillOnClick(SkillValue.Amateur)
        }
      ></Button>
      <Button
        className={`skill-level ${getLevelClass(
          SkillValue.Professional
        )}`}
        title="Professional"
        onClick={(event: SyntheticEvent) =>
          skillOnClick(SkillValue.Professional)
        }
      ></Button>
      <Button
        className={`skill-level ${getLevelClass(
          SkillValue.Specialist
        )}`}
        title="Specialist"
        onClick={(event: SyntheticEvent) =>
          skillOnClick(SkillValue.Specialist)
        }
      ></Button>
      <Button
        className={`skill-level ${getLevelClass(SkillValue.Guru)}`}
        title="Guru"
        onClick={(event: SyntheticEvent) =>
          skillOnClick(SkillValue.Guru)
        }
      ></Button>
    </div>
  );
};

export default SkillSelection;
