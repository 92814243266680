import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import {
  CandidateData,
  ExternalType,
  SkillValueData,
} from '../interfaces';
import { computeCandidateScores } from '../algorithms/compute-scores';
import { toSequence } from '../algorithms/fromSkillTreeToSequence';
import { computeTotalPoints } from '../helpers/scoring';

interface MatchCalculatorWithDisplayProps {
  candidate: CandidateData;
}

const MatchCalculatorWithDisplay = ({
  candidate,
}: MatchCalculatorWithDisplayProps) => {
  const selectedCandidate: CandidateData = useSelector(
    ({ search }) => {
      if (candidate.externalType === ExternalType.Person) {
        return search.peopleSearchSelectedLead;
      }
      if (candidate.externalType === ExternalType.Lead) {
        return search.leadSearchSelectedPerson;
      }
      console.error(
        `Candidate type implementation missing! Please add implementation for type "${candidate.externalType}" in match-calculator-with-display.tsx`
      );
    }
  );

  const totalPoints = useSelector(({ search }) => {
    return search.peopleSearch.sequence.totalPoints;
  });

  // TODO this is being run even if totalPoints is -1
  const candidateScore = useSelector(({ search }) => {
    switch (candidate.externalType) {
      case ExternalType.Person: {
        if (search.peopleSearchScores.length === 0) {
          return 'Init...';
        }
        const person = search.peopleSearchScores.find(
          (person: CandidateData) =>
            person.externalId === candidate.externalId
        );
        return person.score;
      }
      case ExternalType.Lead: {
        if (search.leadSearchScores.length === 0) {
          return 'Init...';
        }
        const lead = search.leadSearchScores.find(
          (person: CandidateData) => {
            person.externalId === candidate.externalId;
          }
        );
        return lead.score;
      }
      default: {
        throw new Error(
          `externalType not implemented in match-calculator-with-display.tsx!`
        );
      }
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCandidate) {
      // 1. Create new sequence
      const sequence: Map<
        string,
        Map<string, SkillValueData>
      > = toSequence(selectedCandidate);

      // 2. Update totalPoints if non-zero, negative values not allowed
      if (totalPoints === -1) {
        const points = computeTotalPoints(sequence);
        if (points > 0) {
          let action = {
            type: 'search/peopleSearchSetTotalPoints',
            payload: points,
          };
          dispatch(action);
          // console.error('MatchCalc Action: ', action);
        }
      }

      const candidateArr: CandidateData[] = [candidate];
      // here: we use the special case of inputting only one-length array of candidate for only computing this match calculator's candidate score
      // 3. Compute matches against sequence, get scoring dispatch actions
      const actionsToDispatch = computeCandidateScores(
        candidateArr, // special case of CandidateData[] is to send only one candidate, although computeCandidateScores accepts multiple
        sequence,
        'search/addPeopleSearchScorePoints'
      );
      /* console.debug(
        `actionsToDispatch for ${candidate.profileInfo?.name}`,
        JSON.stringify(actionsToDispatch)
      ); */
      // 4. Send to store
      for (let action of actionsToDispatch) {
        dispatch(action);
      }
    }
  }, [selectedCandidate]);

  // After candidates have been loaded it is possible to compute scores
  return (
    <div className="lead-match-calculator">
      <div className="score-for-profile-percentage">
        {typeof candidateScore === 'string'
          ? candidateScore
          : ((candidateScore / totalPoints) * 100).toFixed(1) + '%'}
      </div>
    </div>
  );
};

export default MatchCalculatorWithDisplay;
