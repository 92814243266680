import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import DropdownSelect from './semantic-ui/dropdown-select';
import { Candidate, CandidateData } from '../interfaces';
import { findCandidateWithExtId } from '../helpers/candidates';

const LeadSearch = () => {
  let { leadId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Selectors
  const candidates: Candidate[] = useSelector(({ search }) => {
    return search.candidates;
  });

  const personsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData.profiles;
  });
  //Leads without duplicates
  const leadsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData.leads;
  });

  const mappedLeads = createSelector(
    (state) => state.userdata.usersData.leads,
    (leads) =>
      leads.map((lead: CandidateData) => {
        return {
          key: lead.externalId,
          text: lead.externalId,
          value: lead.externalId,
        };
      })
  );

  const leadsOptions = useSelector(mappedLeads);

  // Manage cached userdata loading here EM210518
  useEffect(() => {
    const cachedUserdata = localStorage.getItem('skills-personData');
    // Cached data is not used if userdata has already been updated, or if there is no data in cache
    if (personsData.length === 0 && cachedUserdata) {
      dispatch({
        type: 'userdata/initBatchOfProfiles',
        payload: JSON.parse(cachedUserdata),
      });
    }
  }, []);

  // Manage cached userdata loading here EM210518
  useEffect(() => {
    const cachedLeadData = localStorage.getItem('skills-leadData');
    // Cached data is not used if userdata has already been updated, or if there is no data in cache
    if (leadsData.length === 0 && cachedLeadData) {
      dispatch({
        type: 'userdata/initBatchOfLeads',
        payload: JSON.parse(cachedLeadData),
      });
    }
  }, []);

  // Run first time only
  useEffect(() => {
    if (candidates.length > 0 && candidates[0].score === undefined) {
      dispatch({ type: 'search/initCandidateScores' });
    }

    return () => {
      dispatch({
        type: 'search/setSkillLoader',
        payload: null,
      });
      dispatch({
        type: 'search/setErrorMessage',
        payload: null,
      });
    };
  }, []);

  // Run first time to set selectedLead if there is selected leadId
  useEffect(() => {
    if (leadId) {
      setSelectedLeadData(leadId);
    }
  });
  //TODO fetch lead from server here
  function setSelectedLeadData(id: string) {
    const selectedLeadData: CandidateData = findCandidateWithExtId(
      id,
      leadsData
    );
    dispatch({
      type: 'search/leadSearchSelectedLead',
      payload: selectedLeadData,
    });
  }

  return (
    <div className="leads-view">
      <div className="leads-selections">
        <div className="leads-dropdown">
          Select lead
          <DropdownSelect
            options={leadsOptions}
            onOptionChange={(event, data) => {
              data.options.map((option) => {
                if (option.value === data.value) {
                  dispatch({
                    type: 'search/setSearchLoader',
                    payload: true,
                  });
                  setSelectedLeadData(option.key);
                  navigate(`${option.key}`);
                }
              });
            }}
            placeholderText={leadId ? leadId : 'Select lead'}
          />
        </div>
        {leadId ? (
          <div className="lead-links">
            <a
              rel="noreferrer"
              href={
                'https://pdi-pepron.zendesk.com/agent/tickets/' +
                leadId
              }
              target="_blank"
              title={`Click here to open ticket in Zendesk`}
            >
              <h1>#{leadId}</h1>
            </a>
          </div>
        ) : (
          <p className="lead-links">No selected leads...</p>
        )}
      </div>
      <Outlet
        context={{
          candidates,
          personsData,
        }}
      />
    </div>
  );
};

export default LeadSearch;
