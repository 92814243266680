const initialState = {
  farewellMessage: '',
  errorMessage: '',
  serverStatus: -1,
};

const logoutSlice = (state = initialState, action) => {
  switch (action.type) {
    case 'logout/initValues':
      return { ...initialState, serverStatus: '' };
    case 'logout/success':
      return { ...state, farewellMessage: action.payload };
    case 'logout/setErrorObj':
      return { ...state, error: JSON.stringify(action.payload) };
    case 'logout/serverStatus':
      return { ...state, serverStatus: action.payload };
  }
  return state;
};

export default logoutSlice;
