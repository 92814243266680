import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import reducers from './features/reducer';

let preloadedState;
const persistedLoggedInUserString =
  localStorage.getItem('skills-user');

if (persistedLoggedInUserString) {
  preloadedState = {
    loggedInUser: JSON.parse(persistedLoggedInUserString),
  };
}

function exampleMiddleware(storeAPI) {
  return function wrapDispatch(next) {
    // only called once, next is the next middleware in pipeline
    return function handleAction(action) {
      // called every time an action is dispatched
      // do stuff here

      return next(action); // passes action onwards with next()
    };
  };
}

const es6ExampleMiddleware = (storeAPI) => (next) => (action) => {
  // do stuff here
  return next(action);
};

const loggerMiddleware = (storeAPI) => (next) => (action) => {
  console.log('dispatching', action);
  let result = next(action);
  console.log('next state', storeAPI.getState());
  return result;
};

const middlewares = [loggerMiddleware];

// production mode will not include redux devtools EM210504
const enhancers =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares));

const store = configureStore({
  reducer: reducers,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(enhancers),
});

export default store;
