import { getLeadProfileInfo } from '../helpers/profiles';
import { CandidateData, CandidateInfo, Skill } from '../interfaces';

interface UserdataState {
  usersData: { profiles: CandidateData[]; leads: CandidateData[] };
  myData: { skillTree: Skill; profileInfo: CandidateInfo };
  loading: string;
  loadingProfiles: string;
}

const initialState: UserdataState = {
  usersData: { profiles: [], leads: [] },
  myData: { skillTree: null, profileInfo: null },
  loading: '',
  loadingProfiles: '',
};

/**
 * Server can return duplicate external ids, they are not allowed in frontend computations
 * @param objectArray Profile or Lead arrays must include Candidate base properties
 * @returns
 */
const removeDuplicates = (objectArray: CandidateData[]) => {
  if (!objectArray) return objectArray;
  if (objectArray.length === 0) {
    console.warn('removeDuplicates allows zero array!');
  }
  let keys = objectArray.map((obj) => obj.externalId);
  let filtered = objectArray.filter(
    ({ externalId }, i) => !keys.includes(externalId, i + 1)
  );
  return filtered;
};

const userdataSlice = (state = initialState, action) => {
  switch (action.type) {
    case 'userdata/addMyProfile': {
      return {
        ...state,
        myData: {
          ...state.myData,
          skillTree:
            action.payload /* , profileInfo: { externalId }  */,
        },
        loading: false,
      };
    }
    case 'userdata/addLead': {
      const updatedLeads: CandidateData[] = [
        ...state.usersData.leads,
        action.payload,
      ];
      console.log('userdata/addleads payload: ', action.payload);
      console.log('userdata/addleads state leads: ', updatedLeads);
      const updatedLeadsNoDuplicates = removeDuplicates(updatedLeads);
      return {
        ...state,
        usersData: {
          ...state.usersData,
          leads: updatedLeadsNoDuplicates,
        },
      };
    }
    case 'userdata/initBatchOfProfiles': {
      console.log('userdata/initBatchOf.. payload: ', action.payload);
      console.log('Store profiles: ', state.usersData.profiles);
      const initialProfiles = action.payload;
      const initialProfilesNoDuplicates =
        removeDuplicates(initialProfiles);
      return {
        ...state,
        usersData: {
          ...state.usersData,
          profiles: initialProfilesNoDuplicates,
        },
      };
    }
    case 'userdata/addBatchOfProfiles': {
      const updatedProfiles = [
        ...state.usersData.profiles,
        ...action.payload,
      ];
      const updatedProfilesNoDuplicates =
        removeDuplicates(updatedProfiles);
      return {
        ...state,
        usersData: {
          ...state.usersData,
          profiles: updatedProfilesNoDuplicates,
        },
        loading: false,
      };
    }
    case 'userdata/initBatchOfLeads': {
      const initialLeads = action.payload;
      const initialLeadsNoDuplicates = removeDuplicates(initialLeads);
      return {
        ...state,
        usersData: {
          ...state.usersData,
          leads: initialLeadsNoDuplicates,
        },
      };
    }
    case 'userdata/addBatchOfLeads': {
      const updatedLeads = [
        ...state.usersData.leads,
        ...action.payload,
      ];
      const updatesLeadsNoDuplicates = removeDuplicates(updatedLeads);
      return {
        ...state,
        usersData: {
          ...state.usersData,
          leads: updatesLeadsNoDuplicates,
        },
      };
    }
    case 'userdata/setLoading': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'userdata/setLoadingProfiles': {
      return {
        ...state,
        loadingProfiles: action.payload,
      };
    }
    case 'userdata/setProfileFillLevel': {
      const { id, fillLevel } = action.payload;
      const updatedProfiles = state.usersData.profiles.map(
        (profile) => {
          if (
            profile &&
            profile.profileInfo &&
            profile.profileInfo.id === id
          ) {
            return {
              ...profile,
              profileInfo: {
                ...profile.profileInfo,
                fillLevel: fillLevel,
              },
            };
          } else return profile;
        }
      );
      return {
        ...state,
        usersData: {
          ...state.usersData,
          profiles: updatedProfiles,
        },
      };
    }
  }
  return state;
};

export default userdataSlice;
