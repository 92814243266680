import axios, { AxiosResponse } from 'axios';
import { SkillValueData } from '../interfaces';

const hostURI = '/api/v1/';
const instance = axios.create({
  baseURL: hostURI,
  headers: { 'Content-Type': 'application/json' },
});

// login sets authorization as default header, no need to setup further
const put = (
  authToken: string,
  id: string,
  data: SkillValueData
): Promise<AxiosResponse<any>> =>
  axios.put(`${hostURI}profile/${id}/skillValue`, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });

const del = (
  authToken: string,
  id: string,
  data: SkillValueData
): Promise<AxiosResponse<any>> =>
  axios.delete(`${hostURI}profile/${id}/skillValue`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: {
      source: data,
    },
  });

export default {
  instance,
  put,
  del,
};
