import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import FilterPanel from './filter-panel';
import renderExternalType from './render-external-types';
import { sortCandidatesByScore } from '../helpers/candidates';
import { checkForProfileInfoExistence } from '../helpers/selectors';
import {
  Candidate,
  CandidateData,
  ExternalType,
  Option,
} from '../interfaces';

interface CandidateResultsProps {
  candidatesScores: Candidate[];
  candidatesDataful: CandidateData[];
  externalType: ExternalType;
  totalPoints: number;
}

// We can start rendering already when candidateResults array exists
const CandidateResults = ({
  candidatesScores,
  candidatesDataful,
  externalType,
  totalPoints,
}: CandidateResultsProps) => {
  // Selectors
  const mappedLocations = createSelector(
    (state) => {
      if (checkForProfileInfoExistence(state.userdata)) {
        return state.userdata.usersData.profiles;
      } else return undefined;
    },
    (profiles) => {
      if (profiles === undefined) return undefined;
      const allValidPostcodeEntries = profiles
        .filter((person) => person.profileInfo.postcode !== undefined)
        .map((validLocationPerson) => {
          return validLocationPerson.profileInfo.postcode;
        });
      const uniqueValidEntries = Array.from(
        new Set(allValidPostcodeEntries)
      );
      return uniqueValidEntries.map((entry: string) => {
        return { key: entry, value: entry, text: entry };
      });
    }
  );

  const locationOptions: Option[] = useSelector(mappedLocations);

  const mappedAvailabilities = createSelector(
    (state) => {
      if (checkForProfileInfoExistence(state.userdata)) {
        return state.userdata.usersData.profiles;
      } else return undefined;
    },
    (profiles) => {
      if (profiles === undefined) return [];
      const allValidAvailabilityEntries = profiles
        .filter(
          (person) => person.profileInfo.available !== undefined
        )
        .map((validAvailabilityPerson) => {
          return validAvailabilityPerson.profileInfo.available;
        });
      const uniqueValidEntries = Array.from(
        new Set(allValidAvailabilityEntries)
      );
      uniqueValidEntries.sort();
      return uniqueValidEntries.map((entry: string) => {
        return { key: entry, value: entry, text: entry };
      });
    }
  );

  const availabilityOptions: Option[] = useSelector(
    mappedAvailabilities
  );

  const mappedPools = createSelector(
    (state) => {
      if (checkForProfileInfoExistence(state.userdata)) {
        return state.userdata.usersData.profiles;
      } else return undefined;
    },
    (profiles) => {
      if (profiles === undefined) return [];
      const allValidPoolEntries = profiles
        .filter((person) => person.profileInfo.pool !== undefined)
        .map((validPoolPerson) => {
          return validPoolPerson.profileInfo.pool;
        });
      const uniqueValidEntries = Array.from(
        new Set(allValidPoolEntries)
      );
      return uniqueValidEntries.map((entry: string) => {
        return { key: entry, value: entry, text: entry };
      });
    }
  );

  const poolOptions: Option[] = useSelector(mappedPools);

  const location: string = useSelector(({ search }) => {
    return search.locationFilter;
  });
  const availability: string = useSelector(({ search }) => {
    return search.availabilityFilter;
  });
  const pool: string = useSelector(({ search }) => {
    return search.poolFilter;
  });
  const fillLevel: string = useSelector(({ search }) => {
    return search.fillLevelFilter;
  });
  // Selectors end

  // 1. Apply filters to dataful structure
  const filteredDataful = candidatesDataful
    .filter((dataful) => {
      if (location && location.length > 0) {
        return dataful.profileInfo.postcode === location;
      }
      return true; // bypass filter if location not set
    })
    .filter((dataful) => {
      if (availability && availability.length > 0) {
        return dataful.profileInfo.available <= availability;
      }
      return true; // bypass filter if availability not set
    })
    .filter((dataful) => {
      if (pool && pool.length > 0) {
        return dataful.profileInfo.pool === pool;
      }
      return true; // bypass filter if pool not set
    })
    .filter((dataful) => {
      if (fillLevel && fillLevel.length > 0) {
        return dataful.profile.fillLevel === fillLevel;
      }
      return true; // bypass filter if fill level not set
    });
  // 2. Create array of external ids that are being allowed to render after filtering
  const filteredExtIds: string[] = filteredDataful.map(
    (candidateData) => candidateData.externalId
  );

  // 3. Sort all by scores, then filter those that are not included in the filtered ids array, finally render
  // Sorting by scores will not work when we start rendering, because candidate profile data is still being constructed
  // We can start sorting after profile data is loaded

  let sortedScoreResults;
  if (
    externalType === ExternalType.Person &&
    candidatesDataful.length > 0
  ) {
    console.log('Is here on results ', candidatesScores);
    sortedScoreResults = sortCandidatesByScore(candidatesScores)
      .filter(
        (candidate) =>
          filteredExtIds.indexOf(candidate.externalId) >= 0
      )
      .map((candidate: Candidate, i: number) => {
        return renderExternalType({
          candidate,
          candidatesDataful,
          candidatesScores,
          externalType,
          i,
          totalPoints,
        });
      });
  }
  return (
    <div className="lead-score-result">
      <FilterPanel
        locationOptions={locationOptions}
        availabilityOptions={availabilityOptions}
        poolOptions={poolOptions}
      />
      {candidatesDataful.length > 0 ? (
        <ul>{sortedScoreResults}</ul>
      ) : (
        'No candidates have been loaded.'
      )}
    </div>
  );
};

export default CandidateResults;
