import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

const RedirectingSpinner = () => {
  return (
    <div className="redirecting-spinner">
      <Dimmer active inverted>
        <Loader>Logging in</Loader>
      </Dimmer>
    </div>
  );
};

export default RedirectingSpinner;
