import React from 'react';
import { findCandidateWithExtId } from '../helpers/candidates';
import {
  Candidate,
  CandidateData,
  ExternalType,
} from '../interfaces';
import { Button, Icon } from 'semantic-ui-react';

const LOADING_MESSAGE = 'Waiting for data...';

interface RenderExternalTypeProps {
  candidate: Candidate;
  candidatesDataful: CandidateData[];
  candidatesScores: Candidate[];
  externalType: ExternalType;
  i: number;
  totalPoints: number;
}

const renderExternalType = ({
  candidate,
  candidatesDataful,
  candidatesScores,
  externalType,
  i,
  totalPoints,
}: RenderExternalTypeProps) => {
  let data;
  let nameOrMessage;
  let details;
  if (!candidate) {
    console.error(`renderExternalType: candidate is undefined!`);
    return;
  }
  if (candidatesScores[0].externalType !== externalType) {
    throw new Error(
      'Mismatching external type in candidatesDataful prop, please check that candidates trying to be rendered are correct type!'
    );
  }
  if (candidatesDataful) {
    data = findCandidateWithExtId(
      candidate.externalId,
      candidatesDataful
    );
  }
  if (candidate.externalType === ExternalType.Person) {
    nameOrMessage = data?.profileInfo?.name ?? LOADING_MESSAGE;
    details = ['users', 'user'];
  }
  if (candidate.externalType === ExternalType.Lead) {
    nameOrMessage = data?.externalId ?? LOADING_MESSAGE;
    details = ['tickets', 'ticket'];
  }

  return (
    <li
      id="score-for-list"
      key={`score-for-${candidate.serverId}-${i}`}
    >
      <div className="score-for">
        <div className="profile-name">
          <a
            rel="noreferrer"
            href={`./consultant/${candidate.externalId}`}
            target="_blank"
            title={`Click here to open ${details[1]} profile in new tab`}
          >
            {nameOrMessage}
          </a>
        </div>
        <div className="score-for-profile-percentage">
          {totalPoints === 0
            ? 'Add more requirements'
            : ((candidate.score / totalPoints) * 100).toFixed(1) +
              '%'}
        </div>
        <div className="score-for-links">
          <a
            rel="noreferrer"
            href={`https://pdi-pepron.zendesk.com/agent/${details[0]}/${candidate.externalId}`}
            target="_blank"
            title={`Click here to open ${details[1]} in Zendesk`}
          >
            <Icon name="external alternate" />
          </a>
        </div>
      </div>
    </li>
  );
};

export default renderExternalType;
