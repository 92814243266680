import { Skill, SkillValueData } from '../interfaces';
import * as server from '../api/skills-backend';

const deleteSkillValueFromServer = async (
  authToken: string,
  skill: Skill,
  profileId: string
) => {
  if (!skill) {
    console.error(
      'delete-skill-value-segment.tsx: Missing skill data!'
    );
    return;
  }
  console.log('deleteSkillValueFromServer started...');
  if (profileId.length === 0) {
    console.error(`ProfileID missing! Can't update db!`);
    return;
  }

  console.group('DELETE skill data');
  console.log('skillPath', skill.path);
  console.log('skillId', skill.id);
  console.groupEnd();

  const response = await server.default.del(authToken, profileId, {
    skillPath: skill.path,
    skillId: skill.id,
    skillValue: null,
  });
  console.log(`Server responded `, response.data);
};

const sendSkillValueToServer = async (
  authToken: string,
  id: string,
  skillValueDataEntry: SkillValueData
) => {
  const { skillId, skillPath, skillValue } = skillValueDataEntry;
  if (skillValue === null || skillValue === undefined) {
    console.error('skill-category.tsx: Missing skill value!');
    return;
  }
  console.log('sendSkillValueToServe started...');
  if (id.length === 0) {
    console.error(`id missing! Can't update db!`);
    return;
  }

  console.group('PUT skill data');
  console.log('serverId', id);
  console.log('skillPath');
  console.log('skillId', skillId);
  console.log('skillValue', skillValue);
  console.groupEnd();

  const response = await server.default.put(authToken, id, {
    skillPath: skillPath,
    skillId: skillId,
    skillValue: skillValue,
  });
  console.log(`Server responded `, response.data);
};

export { deleteSkillValueFromServer, sendSkillValueToServer };
