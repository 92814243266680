import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { findCandidateWithExtId } from '../helpers/candidates';
import DropdownSelect from './semantic-ui/dropdown-select';
import { Map } from 'immutable';
import { computeCandidateScores } from '../algorithms/compute-scores';
import { computeTotalPoints } from '../helpers/scoring';
import { toSequence } from '../algorithms/fromSkillTreeToSequence';
import CandidateResults from './candidate-results';
import FillArea from './fill-area';
import {
  Candidate,
  CandidateData,
  ExternalType,
  SkillValueData,
} from '../interfaces';
import { removeDefaultSkills } from '../helpers/skills';

const initialMap = Map<string, Map<string, SkillValueData>>({});

const LeadSearch = () => {
  const dispatch = useDispatch();
  const [findMatchMap, setFindMatchMap] = useState(Map(initialMap));
  const [selectedLead, setSelectedLead] = useState(null);

  // Selectors
  const candidates: Candidate[] = useSelector(({ search }) => {
    return search.candidates;
  });

  const personsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData.profiles;
  });
  //Leads without duplicates
  const leadsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData.leads;
  });

  // const selectedLead: CandidateData = useSelector(({ search }) => {
  //   return search.leadSearchSelectedLead;
  // });
  console.log('here selectedLeAD', selectedLead);
  //The initial skill tree to form selection fetched from server (GET 'skill')
  // const candidateSearchDefaultsData = useSelector(({ search }) => {
  //   return search.searchDefaults;
  // }) as ProfileData;

  const mappedLeads = createSelector(
    (state) => state.userdata.usersData.leads,
    (leads) =>
      leads.map((lead: CandidateData) => {
        return {
          key: lead.externalId,
          text: lead.externalId,
          value: lead.externalId,
        };
      })
  );

  const leadsOptions = useSelector(mappedLeads);
  // ---

  // Manage cached userdata loading here EM210518
  useEffect(() => {
    const cachedUserdata = localStorage.getItem('skills-personData');
    // Cached data is not used if userdata has already been updated, or if there is no data in cache
    if (personsData.length === 0 && cachedUserdata) {
      dispatch({
        type: 'userdata/initBatchOfProfiles',
        payload: JSON.parse(cachedUserdata),
      });
    }
  }, []);

  // Manage cached userdata loading here EM210518
  useEffect(() => {
    const cachedLeadData = localStorage.getItem('skills-leadData');
    // Cached data is not used if userdata has already been updated, or if there is no data in cache
    if (leadsData.length === 0 && cachedLeadData) {
      dispatch({
        type: 'userdata/initBatchOfLeads',
        payload: JSON.parse(cachedLeadData),
      });
    }
  }, []);

  // Run first time only
  useEffect(() => {
    console.log('initial lead and candidate');
    // setSelectedLead(null);
    // dispatch({
    //   type: 'search/leadSearchSelectedLead',
    //   payload: null,
    // });
    if (candidates.length > 0 && candidates[0].score === undefined) {
      dispatch({ type: 'search/initCandidateScores' });
    }
  }, []);

  // Total points computation
  let totalPoints = computeTotalPoints(findMatchMap);

  useEffect(() => {
    console.log('findMatchMap changed:', findMatchMap);
    dispatch({ type: 'search/resetScores' });
    console.log('Computing scores', personsData);
    const scoringActions = computeCandidateScores(
      personsData,
      findMatchMap,
      'search/addScorePoints'
    );
    for (let action of scoringActions) {
      dispatch(action);
    }
  }, [findMatchMap]);

  useEffect(() => {
    console.log('New Lead Selected', selectedLead);
    console.log('Merging the findMatchMap');
    if (selectedLead && selectedLead.profile) {
      const filteredMap = removeDefaultSkills(
        toSequence(selectedLead)
      );
      console.log('FindMatchMap set', filteredMap);
      setFindMatchMap(filteredMap);
      console.log('should start scoring');
    }
  }, [selectedLead]);

  useEffect(() => {
    console.log('candidates changes');
  }, [candidates]);

  return (
    <div className="leads-view">
      <div className="leads-dropdown">
        Select lead
        <DropdownSelect
          options={leadsOptions}
          onOptionChange={(event, data) => {
            data.options.map((option) => {
              if (option.value === data.value) {
                const selectedLeadData = findCandidateWithExtId(
                  option.key,
                  leadsData
                );
                setSelectedLead(selectedLeadData);
                // dispatch({
                //   type: 'search/leadSearchSelectedLead',
                //   payload: selectedLeadData,
                // });
              }
            });
          }}
          placeholderText="Select lead"
        />
      </div>
      <div className="lead-links">
        <h1>
          <a
            href={
              'https://pdi-pepron.zendesk.com/agent/tickets/' +
              selectedLead?.externalId
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            #{selectedLead?.externalId}
          </a>
        </h1>
      </div>
      <div className="fill-area-container">
        {selectedLead ? (
          <FillArea
            serverId={selectedLead?.serverId}
            profileData={selectedLead?.profile}
            onMapChange={(
              // Fires when subskill has changes, either from click or props
              map: Map<string, Map<string, SkillValueData>>
            ) => {
              console.log('onMapChange', map);
              const newMapState = findMatchMap.mergeDeep(map);
              console.log('new map', newMapState);
              setFindMatchMap(newMapState);
            }}
            shouldUpdateServer={true}
          />
        ) : (
          <p>Select a lead!</p>
        )}
      </div>
      <div className="candidate-results-container">
        {personsData ? (
          <CandidateResults
            candidatesScores={candidates}
            candidatesDataful={personsData}
            externalType={ExternalType.Person}
            totalPoints={totalPoints}
          />
        ) : (
          <p>Waiting...</p>
        )}
      </div>
    </div>
  );
};

export default LeadSearch;
