import React, { useState } from 'react';
import { useToggle } from '../helpers/hooks';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { Option } from '../interfaces';

interface FilterProps {
  icon?: string;
  filterValue: string;
  onFilterChange: (value) => void;
  options: Option[];
  title: string;
}

const Filter = ({
  icon,
  filterValue,
  onFilterChange,
  options,
  title,
}: FilterProps) => {
  const [prevFilter, setPrevFilter] = useState(null);
  const [filterChecked, toggleFilterChecked] = useToggle(false);

  const handleChange = (e, { value }) => {
    console.log('Filter handleChange, new value ', value);
    onFilterChange(value);
    if (!filterChecked) {
      toggleFilterChecked();
    }
  };

  const handleOnClose = (e, { value }) => {
    console.log('Hello onClose');
    /*     if (value === prevFilter && !filterChecked) {
      toggleFilterChecked();
    } */
    // onFilterChange(value);
  };

  const handleChecboxChange = () => {
    if (filterChecked) {
      setPrevFilter(filterValue);
      onFilterChange('');
    }
    if (!filterChecked) {
      if (prevFilter) {
        onFilterChange(prevFilter);
        setPrevFilter(null);
      }
    }
    toggleFilterChecked();
  };

  return (
    <div className="filter-interactables">
      <Checkbox
        checked={filterChecked}
        onChange={handleChecboxChange}
        toggle
      />
      <div className="subtitle">
        <h3>{title}</h3>
      </div>
      <Dropdown
        button
        className="icon"
        floating
        labeled
        icon={icon ?? 'world'}
        selectOnBlur={false}
        onChange={handleChange}
        onClose={handleOnClose}
        options={options}
        search
        text={filterChecked ? filterValue : title}
      />
    </div>
  );
};

export default Filter;
