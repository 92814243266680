import { Map } from 'immutable';
import {
  CandidateData,
  SkillValueData,
  Skill,
  SkillValue,
  FillLevel,
} from '../interfaces';

const skillValueHighEnough = (
  skill: Skill,
  skillValueDataEntry: any
  // skillValueDataEntry: SkillValueData
) => {
  return skill.skillValue >= skillValueDataEntry.skillValue;
};

const skillMatchesDataEntry = (
  skill: Skill,
  skillValueDataEntry: any
  // skillValueDataEntry: SkillValueData
) => {
  return (
    skill.id === skillValueDataEntry.skillId &&
    skill.path === skillValueDataEntry.skillPath
  );
};

const passScoreAdditionCheck = (skillValueDataEntry: any) => {
  return skillValueDataEntry.skillValue >= SkillValue.NoUnderstanding;
};

/**
 * computeMatchScores returns an array of actions that can be dispatched to Redux store, which are a sequence of atomic scoring operations
 * @param candidateData Any array of leads or profiles, which must include a skillTree that can be scored the candidates against
 * @param matchingSequence Contains a skill value sequence built out of candidate skill selections
 * @param scoreActionType Type of Redux action created in the action creator
 * @param fillActionType Type of Redux action created in the action creator
 */
const computeCandidateScores = (
  candidateData: CandidateData[],
  matchingSequence: Map<string, Map<string, SkillValueData>>,
  scoreActionType: string
) => {
  let actionsToDispatch: {
    type: string;
    payload: {};
  }[] = [];
  try {
    candidateData?.map(({ profile, profileInfo }, k) => {
      profile.skillTree?.children.map((category, j) => {
        category.children.map((subskill: Skill, i) => {
          if (subskill.skillValue) {
            // Compute the score if skill is found in candidate search sequence
            matchingSequence.forEach((entry) => {
              entry.forEach((skillValueDataEntry, key) => {
                if (
                  skillMatchesDataEntry(
                    subskill,
                    skillValueDataEntry
                  ) &&
                  skillValueHighEnough(subskill, skillValueDataEntry)
                ) {
                  // Found a skill good enough, and will add a skillValue number of points to the user's score if it is greater than actual value of zero
                  // Don't dispatch zeros
                  if (passScoreAdditionCheck(skillValueDataEntry)) {
                    actionsToDispatch.push({
                      type: scoreActionType,
                      payload: {
                        externalId: profileInfo.id,
                        scoreAddition: Number(
                          skillValueDataEntry.skillValue
                        ),
                      },
                    });
                    /*                 console.log(
                      `Added points for user ${profileInfo.name}, for skill ${subskill.name} and the amount of points was ${skillValueDataEntry.skillValue}`
                    ); */
                  }
                }
              });
            });
          }
        });
      });
    });
  } catch (error) {
    console.error(`Could not compute scores due to error: `, error);
    return;
  }
  return actionsToDispatch;
};

export { computeCandidateScores };
