import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Map } from 'immutable';
import CandidateResults from './candidate-results';
import FillArea from './fill-area';
import {
  ExternalType,
  CandidateData,
  SkillValueData,
} from '../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { computeCandidateScores } from '../algorithms/compute-scores';
import { computeTotalPoints } from '../helpers/scoring';
import { toSequence } from '../algorithms/fromSkillTreeToSequence';
import { removeDefaultSkills } from '../helpers/skills';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import ErrorMessage from './error-message';

const initialMap = Map<string, Map<string, SkillValueData>>({});
type ContextType = {
  candidates: CandidateData[];
  personsData: CandidateData[];
};
const LeadSearchColumns = () => {
  const { leadId } = useParams();
  const { candidates, personsData } = useOutletContext<ContextType>();
  const dispatch = useDispatch();
  const [findMatchMap, setFindMatchMap] = useState(Map(initialMap));

  // Total points computation
  let totalPoints = computeTotalPoints(findMatchMap);

  const selectedLeadData: CandidateData = useSelector(
    ({ search }) => {
      return search.leadSearchSelectedLead;
    }
  );

  const isLoading = useSelector(({ search }) => {
    return search.leadSearchIsLoading;
  });

  useEffect(() => {
    dispatch({ type: 'search/resetScores' });
    const scoringActions = computeCandidateScores(
      personsData,
      findMatchMap,
      'search/addScorePoints'
    );
    for (let action of scoringActions) {
      dispatch(action);
    }
    if (findMatchMap.size > 0) {
      dispatch({
        type: 'search/setSearchLoader',
        payload: false,
      });
    }
  }, [findMatchMap]);

  useEffect(() => {
    if (selectedLeadData && selectedLeadData.profile) {
      const filteredMap = removeDefaultSkills(
        toSequence(selectedLeadData)
      );
      setFindMatchMap(filteredMap);
    }
  }, [selectedLeadData, leadId]);

  return (
    <div className="leads-columns">
      <div className="fill-area-container">
        {leadId ? (
          <div className="dimmer-loader">
            <Dimmer active={isLoading} inverted>
              <Loader inverted content="Loading" />
            </Dimmer>
            {!isLoading && (
              <FillArea
                serverId={selectedLeadData?.serverId}
                profileData={selectedLeadData?.profile}
                onMapChange={(
                  // Fires when subskill has changes, either from click or props
                  map: Map<string, Map<string, SkillValueData>>
                ) => {
                  const newMapState = findMatchMap.mergeDeep(map);
                  setFindMatchMap(newMapState);
                }}
                shouldUpdateServer={true}
              />
            )}
          </div>
        ) : (
          <p>Select a lead!</p>
        )}
      </div>
      <div className="candidate-results-container">
        {personsData ? (
          <CandidateResults
            candidatesScores={candidates}
            candidatesDataful={personsData}
            externalType={ExternalType.Person}
            totalPoints={totalPoints}
          />
        ) : (
          <p>Waiting data...</p>
        )}
        <ErrorMessage />
      </div>
    </div>
  );
};

export default LeadSearchColumns;
