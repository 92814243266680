import { Map, fromJS } from 'immutable';
import {
  CandidateData,
  SkillValueData,
  SkillValue,
} from '../interfaces';

// type SubsequenceArrayEntry = [string, SkillValueData];

/**
 * Returns an Immutable.js Map sequence of unordered skill value data where skills are in a map list and the inner map contains path data for skills
 * @param candidateData Skill whose ProfileData skillTree is converted into an Immutable.js map sequence
 * @returns
 */
const toSequence = (candidateData: CandidateData) => {
  let sequenceMap: Map<string, Map<string, SkillValueData>> = Map({});
  let subsequenceMap: Map<string, SkillValueData>;

  try {
    for (let category of candidateData.profile.skillTree.children) {
      subsequenceMap = Map({});
      const categoryName = category.name;
      let subskillObj: Map<string, SkillValueData> = Map();
      (category.children || []).forEach(
        ({ name, id, path, skillValue }) => {
          const skillValueDataEntry: SkillValueData = {
            skillId: id,
            skillPath: path,
            skillValue: skillValue ?? SkillValue.Empty,
          };
          subskillObj = subskillObj.set(name, skillValueDataEntry);
        }
      );
      sequenceMap = sequenceMap.set(categoryName, subskillObj);
    }
    return sequenceMap;
  } catch (error) {
    console.error('toSequence error! See details: ', error);
    return null;
  }
};

export { toSequence };
