import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { CandidateData, Candidate, Option } from '../interfaces';
import { findCandidateWithExtId } from '../helpers/candidates';
import CandidateDisplayList from './candidate-display-list';
import DropdownSelect from './semantic-ui/dropdown-select';

const PeopleSearch = () => {
  const dispatch = useDispatch();

  // personsData undefined before addBatch returns with data EM150519
  const personsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData?.profiles;
  });

  const leadsData: CandidateData[] = useSelector(({ userdata }) => {
    return userdata.usersData?.leads;
  });

  const candidates: Candidate[] = useSelector(({ search }) => {
    return search.candidates;
  });

  const mappedLeads = createSelector(
    (state) => state.userdata.usersData.leads,
    (leads) =>
      leads.map((lead: CandidateData) => {
        return {
          key: lead.externalId,
          text: lead.externalId,
          value: lead.externalId,
        };
      })
  );

  const leadsOptions: Option[] = useSelector(mappedLeads);

  const personsLoadedAmount = useSelector(({ userdata }) => {
    return userdata.usersData.profiles.length;
  });

  // Manage cached userdata loading here EM210518
  useEffect(() => {
    const cachedUserdata = localStorage.getItem('skills-personData');
    // console.debug('cachedUserdata: ', cachedUserdata);
    // Cached data is not used if userdata has already been updated, or if there is no data in cache
    if (personsData && personsData.length === 0 && cachedUserdata) {
      dispatch({
        type: 'userdata/initBatchOfProfiles',
        payload: JSON.parse(cachedUserdata),
      });
    }
  }, []);

  // People search only manages updating peopleSearchScoring
  // As long as loaded persons amount is >0 it is ok to init all scores to loaded persons
  useEffect(() => {
    if (personsLoadedAmount > 0) {
      dispatch({
        type: 'search/initPeopleSearchScores',
      });
    }
  }, [personsLoadedAmount]);

  return (
    <div className="people-search">
      <div className="leads-dropdown">
        Select
        <DropdownSelect
          options={leadsOptions}
          onOptionChange={(event, data) => {
            // console.log(`New selection is ${JSON.stringify(data)}`);
            data.options.map((option) => {
              if (option.value === data.value) {
                const selectedCandidate = findCandidateWithExtId(
                  option.key,
                  leadsData
                );
                console.log('Selected candidate', selectedCandidate);
                dispatch({
                  type: 'search/peopleSearchSelectedLead',
                  payload: selectedCandidate,
                });
              }
            });
          }}
          placeholderText="Select lead"
        />
      </div>
      <div className="leads-list">
        <CandidateDisplayList
          candidateData={personsData}
          candidatesPool={candidates}
        />
      </div>
    </div>
  );
};

export default PeopleSearch;
