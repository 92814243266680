const initialState = {
  authToken: null,
  email: undefined,
  gLoginInformation: null,
  serverId: undefined,
  externalData: null,
  redirectAnimation: '',
  error: '',
};

const loginSlice = (state = initialState, action) => {
  switch (action.type) {
    case 'login/gLoginInformation':
      return { ...state, gLoginInformation: action.payload };
    case 'login/setRedirectAnimation':
      return { ...state, redirectAnimation: action.payload };
    case 'login/addProfileData':
      const { authorizationToken, email, profileId, zendeskData } =
        action.payload;
      return {
        ...state,
        authToken: authorizationToken,
        email,
        serverId: profileId,
        externalData: zendeskData,
        redirectAnimation: 'active',
      };
    case 'login/setErrorObj':
      return { ...state, error: JSON.stringify(action.payload) };
  }
  return state;
};

export default loginSlice;
