import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorComponent = () => {
  const error = useRouteError() as Error;

  return (
    <div className="error-container">
      <h1>Error</h1>
      <h2>Ooops, something went wrog...</h2>
      <p>{error.message}</p>
    </div>
  );
};

export default ErrorComponent;
