export interface Skill {
  id: string;
  path: string;
  name: string;
  children: Skill[];
  parents: string[];
  skillValue?: SkillValue;
}

export interface SkillValueData {
  skillPath: string;
  skillId: string;
  skillValue: SkillValue;
}

export enum UserRole {
  Consultant = 'end-user',
  Agent = 'agent',
}

export enum ExternalType {
  Person = 'person',
  Lead = 'lead',
}

export enum AccessType {
  View = 'view',
  Edit = 'edit',
}

export enum SkillValue {
  Empty = -1,
  NoUnderstanding = 0,
  Beginner = 1,
  Amateur = 2,
  Professional = 3,
  Specialist = 4,
  Guru = 5,
}

export enum FillLevel {
  Full = 'full',
  Partial = 'partial',
}

// export interface SkillElement {
//   elem: React.ReactNode;
// }

export type UserTuple = [string, string];

export interface CandidateInfo {
  available?: string;
  id: string;
  name: string;
  photo?: URL;
  pool: string;
  postcode?: string;
  role: UserRole;
  url?: URL;
  fillLevel?: string;
}

// also LeadData
export interface ProfileData {
  skillTree: Skill;
  externalId: string;
  externalType: ExternalType;
  fillLevel?: string;
}

// available profiles for authToken are called candidates, they can be either profiles or leads
export interface Candidate {
  serverId: string;
  externalId: string;
  externalType: ExternalType;
  access: AccessType;
  score?: number;
}

// part of search feature
export interface CandidateData extends Candidate {
  profileInfo: CandidateInfo;
  profile: ProfileData;
  score?: number;
}

export interface RouterLocationState {
  from: { pathname: string };
}

export interface Option {
  key: string;
  value: string;
  text: string;
  image?: { avatar: Boolean; src: URL };
}

export interface MatchPathState {
  params: {
    externalId: string;
  };
}

export interface LocationState {
  from: {
    pathname: string;
  };
}
